@import 'variables';

FORM{}
/* FORM .row, FORM div{margin-bottom:1em;} */
FORM input, textarea, select, option, .input-group-addon, .bootstrap-tagsinput, .form-input{background-color:#E7E7E7;color:#666;border:none!important;border-bottom:2px solid #CCC!important;font-size:1.1em!important;margin-bottom:1.33em;}
FORM input, textarea, select, option, .bootstrap-tagsinput {width:100%;padding:5px;}
FORM textarea{width:100%;min-height:8em;}
FORM input[type="checkbox"], input[type="radio"]{width:auto;margin-left:.5em;margin-right:.33em;}
FORM button, button, .boton-1{background:#ac1e14;border:none;color:white;padding:.5em 1em;font-size:1.1em;}
FORM label{font-weight:bold;clear:both;font-size:1.2em;text-transform:uppercase;margin-right:1em;}
FORM #message{text-align:center;font-weight:bold;}
FORM span.help{color:rgb(133, 133, 133);}

.tarjeta input, .tarjeta textarea, .tarjeta select, .tarjeta option{
  background-color:$orlarus-white-3;
}

.choice-item label{
  font-size:1em !important;
  font-weight:normal !important;
}

/* Customize the label (the container) */
.checkbox-container {
    display: block;
    position: relative;
    text-align:center;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.4em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: rgb(218, 218, 218);
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #555;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: $orlarus-red;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: .2em;
    top: .1em;
    width: .6em;
    height: .6em;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .bootstrap-tagsinput .tag{
    margin-right: 2px;
    color: white;
    background: $orlarus-dark;
    border-radius: .3em;
    padding: .15em .66em;
  }

  .btn.grey{
    background-color:$orlarus-grey;
  }

  .is-valid{
    border-color: $orlarus-green;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='"+$orlarus-green-urlparsed+"' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }

  .is-invalid{
    border-color: $orlarus-red!important;
    background-color: $orlarus-red-light-1!important;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='"+$orlarus-red-urlparsed+"'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='"+$orlarus-red-urlparsed+"' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }

  .repeat-error{
    color: $orlarus-red;
    font-weight:bold;
    margin-bottom:1.5em;
  }