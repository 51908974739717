@import "bootstrap";
@import './style-legacy';
@import './estilos-legacy';
@import './partials/variables';
@import './partials/utils';
@import './partials/forms';
@import './partials/index';
@import './partials/responsive_index';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&display=swap');

.interespacio-1{margin-top:1em;margin-bottom:1em;}

/* COLOR vars */
body {
    background-color: $orlarus-bg-color-light;
}

a{
    text-decoration:none;
}

a svg.svg-inline--fa, button .svg-inline--fa{
    margin-right:.5em;
}

a svg.no-margin.svg-inline--fa, button .no-margin.svg-inline--fa{
    margin-right:0!important;
}

/* INDEX VIDEO BG */

.pattern-overlay {
    background: url('../images/bg/pattern-bg-1.png') repeat rgba(0,0,0,0.5);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index:-15;
    -webkit-transform:translate3d(0,0,0);
    transform: translate3d(0,0,0);
    padding:0;
  }
  
  .fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index:-25;
    -webkit-transform:translate3d(0,0,0);
    transform: translate3d(0,0,0);
    padding:0;
  }
  
  .fullscreen-bg__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding:0;
  }



/* SWAL SWEETALERT2 STYLES*/

.swal2-popup{
    font-size:1em!important;
}

.swal2-confirm{
    background: $orlarus-red!important;
}
.swal2-icon.swal2-info{
    border-color: $orlarus-red!important;
    color: $orlarus-red!important;
}

/* Checked and unchecked */

.color-green{
    color:$orlarus-green;
}

.color-red{
    color:$orlarus-red;
}

.modal-header{

    .modal-title{
        margin-top:0!important;
        font-size:1.1em;
    }

    button.close{
        padding: 0;
        background:none;
        font-weight: bold;
        color:$orlarus-grey;
        font-size:2em;
    }
}


/* NAVBAR */

.navbar{
    background:$orlarus-dark;
    color:$orlarus-white;
    font-size:.8em;
    min-height:6.5em;
}

.navbar-collapse{
    flex-direction: row-reverse;
}

ul.navbar-nav {
    text-align:center;
}

ul.navbar-nav li{
    display:inline-block;
    float:none;}

ul.navbar-nav li a{
    color:$orlarus-white;
    font-size: 1em;
    text-transform: uppercase;
    padding: 1em;
    display: block;
}

ul.navbar-nav li a:hover, ul.navbar-nav .active a{
    background:$orlarus-red;
}

ul.navbar-nav li a:hover{
    color: $orlarus-white;
}

.navbar-brand{
    margin-left:2em;
}


/* Tarjetas */

.tarjeta{
    padding: 1em;
}

.tarjeta-cuerpo, .tarjeta-header{
    background: $orlarus-white;
}

.tarjeta-cuerpo{
    padding:1em;
}

.tarjeta-header{
    padding:.66em 1em 0 1em;
    font-size:1.1em;
    font-weight:bold;
    text-transform: uppercase;
    color:$orlarus-grey;
}

.tarjeta-cuerpo{
    table{
        width:100%;
        font-size:1em;
        tr:nth-child(even) {background: #f3f3f3;}
    }
    
}

.btn-option-under-image{
    background:$orlarus-red;
    color:$orlarus-white;
    font-weight:bold;
    padding:.5em;
    display:block;
    border:1px solid #981b12;
    text-align:center;
}

.btn-option-under-image.selected{
    background:$orlarus-black;
}

.btn-option-under-image.selected{
    background:$orlarus-black;
}

.btn-option-under-image:hover{
    background:$orlarus-red-2;
    color:$orlarus-white!important;
    }

.navbar-toggler{
    color:#f1f0f0;
}