// Variables

$orlarus-red: #ac1e14;
$orlarus-red-urlparsed: '%23ac1e14';
$orlarus-red-2: #941a11;
$orlarus-red-3: #e4cdcb;
$orlarus-red-light-1: #f2e3e3;
$orlarus-white-2: #F5F5F5;
$orlarus-white-3: #F3F3F3;
$orlarus-dark:#232628;
$orlarus-black: #17191a;
$orlarus-green: #126a15;
$orlarus-green-urlparsed: '%23126a15';
$orlarus-green-light: #2eb133;
$orlarus-green-light-2:rgb(223, 235, 223);
$orlarus-grey: #656565;
$orlarus-grey-light: #c2c2c2;
$orlarus-grey-light-2: #a0a0a0;
$orlarus-grey-light-3: #878787;
$orlarus-grey-light-1: rgb(222,222,222);
$orlarus-yellow:#e8c815;
$orlarus-yellow-dark:#e7c500;

$border-radius-btn: .3em;

$orlarus-bg-color-light: lightgray;
$orlarus-bg-color-white: #F5F5F5;
$orlarus-white: #FFF;