@charset "utf-8";
/*
Theme Name: Pixelarus 2015
Theme URI: http://pixelarus.com
Description: Tema original para Pixelarus.com
Version: 1
Author: Jose Luis Quintanilla
Author URI: http://Pixelarus.com
*/


ul{margin:0;padding:0;}
/* .row{margin-bottom:2em;} */

.bloque-login{max-width:820px;margin:auto;padding:2em;background:#F0F0F0;}

h1{font-weight:bold;}
.titulo-pagina{text-align:center;text-transform:uppercase;font-size:3.5em;color:#F7f6f6;position: relative;text-shadow:2px 2px 0px #232628;
  top: 50%;
  transform: translateY(-50%);}
.textesp-1{font-size:1.6em;text-align:center;margin:3em;line-height:1.5em;}

#prefooter{background:#f0f0f0;line-height:1.8em;}
#prefooter h3{font-size:1.2em;font-weight:bold;text-transform:uppercase;margin:0;padding:0;line-height:2em;margin-bottom:1em;}
#prefooter ul{list-style:none;}
#prefooter ul li{line-height:2em;}
#widget ul li{list-style:none;}

a{color:#ca1b0f;}
a:hover{color:#0182bf;text-decoration:none;}
.nav>li>a:hover{background:none;}

.navbar-collapse{border-top:none;box-shadow:none;-webkit-box-shadow:none;}

.footer-pagina{background:#232628;color:#D0D0D0;padding:3em;margin-top:3em}




.bloque h4{font-weight:bold;color:#F0F0F0;background:#232323;padding:1em;margin-bottom:1em;text-transform:uppercase;}




.fondo-rojo{background:#ac1e14;color:white;} /* FONDO ROJO */
.fondo-azul{background:#007eba;color:white;} /* FONDO AZUL */
.fondo-verde{background:#8cac33;color:white;} /* FONDO VERDE  */
.fondo-oscuro{background:#232628;color:white;} /* FONDO OSCURO */
.fondo-medio-oscuro{background: #3e4347;color:white;} /* FONDO OSCURO */
.fondo-claro{background:#f8f7f7;}
.fondo-claro-trans-66{background: rgba(255,255,255,0.66)}

.fondo-oscuclaro-1{background: #ECECEC ;}
 
.bloque-1{padding:30px;background:#232628;color:white;}
.bloque-2{padding:30px;background:#ac1e14;color:white;}

.interespacio{padding:3em;clear:both;}

.menu-cat{text-align:center;}
.menu-cat ul{list-style:none;display:inline-block;margin:0;padding:0;}
.menu-cat ul li{list-style:none;float:left;text-align:center;margin:10px 20px;font-size:1.25em;padding-bottom:8px;}
.menu-cat ul li .selected{border-bottom:2px solid #F0F0F0;}


.cliente_logo{overflow:hidden;padding-left:10px;padding-right:10px;}
.cliente_logo img{width:100%;height:auto;margin-bottom:5px;transition: all 0.3s ease 0s;}
.cliente_logo h4{text-align:center;color:#333;font-size:.9em;margin-bottom:45px;}
.cliente_logo a:hover{text-decoration:none;}
.cliente_logo img:hover{opacity:.7;}


.puntolista{height:50px;width:50px;}
.cojin-grande{text-align:center;}
.cojin-grande div img{width:50%;height:auto;}
.cojin-grande div{position:absolute;top:0;left:0;padding:25% 20%;width:100%;color:#f8f7f7;top: 50%;  transform: translateY(-50%);}
.cojin-grande div h3{margin-top:0;margin-bottom:0;font-size:19.1667px;line-height:1.25em;}

.cojin-azul{background:url(https://pixelarus.com/wp-content/themes/pixelarus-2015/images/cojin/cojin_5.svg); background-size:cover;display:inline-block;float:left;margin:0px 40px 0px 0px;}

.cojin-claro{background:url(https://pixelarus.com/wp-content/themes/pixelarus-2015/images/cojin/cojin_4.svg); background-size:cover;display:inline-block;float:left;margin:0px 40px 0px 0px;}

.cojin-verde{background-size:contain;background:url(https://pixelarus.com/wp-content/themes/pixelarus-2015/images/cojin/cojin_3.svg); background-size:cover;display:inline-block;float:left;margin:0px 40px 0px 0px;}

.cojin-rojo{background:url(https://pixelarus.com/wp-content/themes/pixelarus-2015/images/cojin/cojin_2.svg); background-size:cover;display:inline-block;float:left;margin:0px 40px 0px 0px;}

.cojin-oscuro{background:url(https://pixelarus.com/wp-content/themes/pixelarus-2015/images/cojin/cojin_1.svg); background-size:cover;display:inline-block;float:left;margin:0px 40px 0px 0px;}


.error{color:#7C0002;font-size:1.25em;}
.deshabilitado{background:#CCC;color:#888;}
.danger{background:#7C0002!important;color:white;}
.bg-success, .bg-danger{padding:1.5em;}
.bg-danger{color: #990002;}

.mensaje-action-OK{background-color:#E0F3DF;color:#174711;padding:1em;}
.mensaje-action-ERROR{background-color:#7C0002;color:#7C0002;padding:1em;}

.boton_close{float:right;color:#F0F0F0;}


button .glyphicon{margin-right:1em;}


/************** ESPECIALES */

.clear{clear:both;}
.x-centrar{margin:auto;float:none;}

.vcenter {
display: flex;
  flex-direction: column;
  justify-content: center;
}
.aligner-middle{
  display: flex;
  align-items: center;
  justify-content: center;
}

.oculto{display:none;}



/*** TABLA ***/


ul#lista_diseños{list-style:none;}
ul#lista_diseños li{list-style:none;text-align:center;}
ul#lista_diseños li img{width:100%;}
ul#lista_diseños li button{}


ul.lista-fotos-alumnos{list-style:none;}
ul.lista-fotos-alumnos li{list-style:none;text-align:center;height:600px;overflow: hidden;}
ul.lista-fotos-alumnos li img{width:100%;}
ul.lista-fotos-alumnos li button{}


/* FORMULARIO DE CONTACTO */


.boton-1:hover, .boton-1:active, .boton-1:focus {color:white;text-decoration: none;}
.boton-2{background:#ac1e14;border:none;color:white;padding:.5em 1.5em;margin:.8em auto;font-size:1em;}

.boton-seleccion-fotos{width:100%;padding:1em;font-size:1em;text-align:center;background:#A3a3a3;}
.boton-seleccion-fotos:hover{background:#CFCFCF;}
.boton-seleccion-fotos .glyphicon{margin-right:0;font-size:1.5em;}


.size-1{padding:.5em 1em;font-size:1em;}

.input-group-addon{border-radius:0;}

.votacion_bloque{background-color:#f0f0f0;padding:2em;margin:1em;width:auto;}

ul.votacion li{list-style:none;padding:10px}
ul.votacion li label{width:auto;display:inline;font-weight:normal;text-transform:none;font-size: 1em;}
ul.votacion li input{width:auto;margin-right: 1em;float:left;}

/* ANIMACIÓN CARGANDO ... */

.glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}

select[name=datatable_length] {
	width: auto!important;
}

select[name=datatable2_length] {
	width: auto!important;
}

select[name=datatable3_length] {
	width: auto!important;
}

input[type=search] {
	background:#F0F0f0;color:#555;border:none;border-bottom:2px solid #CCC;font-size:1.2em;
}

table.dataTable tbody tr.selected {
	background-color: #B0BED9 !important;
}

table.dataTable tbody tr {
	background:#F0F0f0!important;
}

.dtr-title {
	font-weight:bold;
}

tr.child ul {
	list-style-type: none;
}

#datatable_container div, #datatable2_container div, #datatable3_container div {
	margin-bottom: auto;
}

#datatable_container label, #datatable2_container label, #datatable3_container label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
	font-size: 1em;
}

#datatable_container input, #datatable2_container input, #datatable3_container input {
	width: auto;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child {
	cursor: pointer;
}

.modal-title {
	float: left;
}

.clear {
	clear: both;
}

#calendarArea {
	margin-top: 2em;
}

.bootstrap-tagsinput {
	box-shadow: none!important;
	border-radius: 0!important;
}

.bootstrap-tagsinput input {
	border-bottom: 0!important;
	margin: 5px!important;
	font-size: 0.9em!important;
}

.tt-menu div {
	margin-bottom: auto!important;
}

.row-designs {
	padding-bottom: 1em;
}

.datatable-area input, .datatable-area select {
	width: auto;
	padding: 0!important;
}

.row-group-input {
	background-color: #f0f0f0;
	padding-top: 10px;
}

.datetimepicker th {
	background-color: white;
	color: black;
}

.form-check label{
	position: relative;
	cursor: pointer;
	color: #666;
}

/*Check box*/
input[type="checkbox"] + .label-text:before{
	content: "\f096";
	font-family: "FontAwesome";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing:antialiased;
	width: 1em;
	display: inline-block;
	margin-right: 5px;
}

input[type="checkbox"]:checked + .label-text:before{
	content: "\f14a";
	color: #ac1e14;
	animation: effect 250ms ease-in;
}

input[type="checkbox"]:disabled + .label-text{
	color: #aaa;
}

input[type="checkbox"]:disabled + .label-text:before{
	content: "\f0c8";
	color: #ccc;
}

.datatable-small-1 label {
	font-size: 0.9em;
}

.datatable-small-1 select option {
	padding: 0!important;
}

ul#selectionArea {
	list-style: none;
}

.orlarusRed {
	color: #ca1b0f;
}

.darkGreen {
	color: darkgreen;
}

.pricearea {
	font-size: 1.5em;
}

.columnaregistro .row .col-md-6, .columnaregistro .row .col-md-12 {
	padding: 10px;
}

.danger .help-block {
	color: white;
}