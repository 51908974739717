

/* VERTICAL ALIGN MIDDLE */
.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .flex-align-middle {
    
  }

table.full-width{
  width:100%;
}

  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
    flex-wrap: wrap;
  }
  .row-eq-height > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }