#orlas-info-contenedor{
    background-size:auto auto;
    background-size:contain!important;
    height:50vh;
}

.centros-lista .item img{}

#orlas-info-1{font-size:2em;margin-top: .66em;}

@media (max-width: 1920px) {

    img.orlarus-hero{
        max-width:40%;
    }

}

@media (max-width: 600px) {

    .puntos-destacados p{
        font-size:1em;
    }

    .puntos-destacados h4, .puntos-destacados .h4{
        font-size:1.1em;
    }

    img.orlarus-hero{
        max-width:80%;
    }

    #orlas-info-1{
        font-size:1.2em;
    }

    #orlas-info-contenedor{
        height:50vh;
        background-size: 175%!important;
    }

    h3.titulo-pagina{
        font-size:2.5em;
    }

    ul.socialnetworks{
        padding-top:1.5em;
        padding-bottom:1.5em;
    }

    .centros-lista .item img{
        height:auto;
        width:100%;
        margin-top:1em;
        margin-bottom:1em;
    }

    .centros-lista .item{
        height:8em;
        text-align:center;
    }
    
    .bloque-diapo-1{
        padding:1em .5em;
    }

    #formContacto input, #formContacto textarea{
        margin-bottom:1em;
    }

}