#footer{
    padding-top:2em;
    padding-bottom:2em;
}

.socialnetworks li{
    display:inline-block;
}

.socialnetworks li a img{
    display:inline-block;
    height:3em;
}

.centros-lista .item{
    text-align:center;
}

.centros-lista .item img{max-width:80%;vertical-align: middle;opacity:.8;margin:auto;}
.centros-lista .item img:hover{opacity:1;}
.centros-lista .item{height:10em;text-align:center;}

.punto-destacado.par{
    background-color: $orlarus-grey-light-1;
}

.punto-destacado.rojo{
    background-color: $orlarus-red;
    color:$orlarus-white;
}

.punto-destacado{
    padding: 1.2em;
}

.puntos-destacados h4{text-transform:uppercase;font-size:1.4em;margin:none;font-weight:bold;}
.puntos-destacados ul li{font-size:1em;list-style: square;text-align:left;margin-left:1em;margin-top:.33em;margin-bottom:.33em;}
.puntos-destacados p{font-size:1.1em;margin:0;padding:0;}
.puntos-destacados a:hover{color:#F0F0F0;}
.puntos-destacados .imagen-puntos-destacados{text-align:center;}
// .puntos-destacados img{opacity:.9;}

.paquete-orla{
    margin:.33em;
    // border: 3px solid $orlarus-black;
    border-radius:.88em;
    overflow:hidden;
    box-shadow: 0 0 2em #CCC;
}

.paquete-orla.tradicional{
    border: 3px solid $orlarus-red;
    border-radius:.88em;
    overflow:hidden;
}

.paquete-orla-header.tradicional{
    background-color:$orlarus-red;
    color:$orlarus-white;
    padding:.5em 1em;
    text-align:center;
}

.paquete-orla-img{
    overflow:hidden;
    height: 20vh;
    width:100%;
    background-position:center;
    background-size:cover;
}

.paquete-orla-header{
    background-color:$orlarus-black;
    color:$orlarus-white;
    padding:.5em 1em;
    text-align:center;
}

.paquete-orla-band.yellow{
    background-color:$orlarus-yellow-dark;
    font-weight:bold;
    color:$orlarus-white;
    text-shadow: 2px 2px 2px black;
    padding:.3em 1em;
    text-align:center;
}

.paquete-orla-band.grey{
    font-size: .9em;
    color: #909090;
    padding:.3em 1em;
    text-align: center;
    border-top: 1px solid #909090;
}

.paquete-orla-cuerpo{
    background-color:$orlarus-white;
    padding:1em;
}

.paquete-orla-cuerpo ul li{
    font-size:.9em;
}

.paquete-orla-pie{
    text-align:center;
    font-size:1em;
    background: $orlarus-white;
    color: #6d6d6d;
}

.paquete-orla-band.grey .precio{
    color: #ac1e14;
    font-size:1.4em;
    font-weight:bold;
    margin:0 .4em;
}

.paquete-orla-band.precio{
    text-align:center;
    color: #6d6d6d;
    font-size:1.4em;
    font-weight:bold;
}

.paquete-orla-pie .precio{
    font-size:1.2em;
    font-weight:bold;
    margin: 0 .4em;
    display: block;
}

.bloque-diapo-1{
    padding:2em 0;
}

.boton-flyer{
    background: #ac1e14;
    border: none;
    color: white;
    padding: 0.4em 1.3em;
    margin: 10px auto;
    font-size: 1.1em;
    cursor:pointer;
}

a.link-sobre-blanco:hover{
    color:$orlarus-grey;
    text-decoration: underline;
}